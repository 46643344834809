import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";

import API, { returnData } from "../components/API";
import Layout from "../components/layouts/Layout";
import { Section, ContactForm } from "../components/elements";
import {
  Grid,
  Col,
  Mutation,
  ErrorLoadBlock,
  Alert,
} from "../components/utilities";

const sendToWP = (data) =>
  API.post(`/submission`, { ...data }).then(returnData);

const ContactPage = ({ data }) => {
  const info = data.allWpConfig.edges[0].node.configFields;
  const node = data.allWpPage.edges[0].node;
  const [success, setSuccess] = useState(false);

  return (
    <Layout
      internal={true}
      title={node.pageFields.seoTitle}
      description={node.pageFields.seoDescription}
    >
      <Section>
        <Grid modifiers={["gap"]}>
          <Col>
            <h2>{node.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: node.content,
              }}
            ></div>
            <p>
              <strong>Email: </strong>{" "}
              <a href={`mailto: ${info.email}`}>{info.email}</a>
            </p>
            <p>
              <strong>Phone: </strong>{" "}
              <a href={`tel: ${info.phoneNumber}`}>{info.phoneNumber}</a>
            </p>
            <p>
              <strong>Address:</strong>
              <p>
                {info.addressOne}
                <br />
                {info.addressTwo}
              </p>
            </p>
          </Col>
          <Col>
            {success ? (
              <Alert modifiers={["success"]}>
                Thank you! We will be in touch with you!
              </Alert>
            ) : (
              <Mutation mutation={sendToWP} postFunc={() => setSuccess(true)}>
                {(contact, { mutProps }) => (
                  <Fragment>
                    <ErrorLoadBlock {...mutProps} />
                    <ContactForm onSubmit={contact} />
                  </Fragment>
                )}
              </Mutation>
            )}
          </Col>
        </Grid>
      </Section>
    </Layout>
  );
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPageQuery {
    allWpPage(filter: { slug: { eq: "contact" } }) {
      edges {
        node {
          id
          title
          content
          pageFields {
            seoTitle
            seoDescription
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allWpConfig {
      edges {
        node {
          configFields {
            phoneNumber
            email
            addressOne
            addressTwo
          }
        }
      }
    }
  }
`;
