import axios from "axios"

export class AuthError extends Error {}

const API = axios.create({
  baseURL: `${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/api_gatsby/v1`,
  responseType: "json",
})

API.interceptors.response.use(
  res => res,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        const error = new AuthError("User Is Not Authenticated")
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export default API

/**
 * Returns Data From API in data
 * @param {object} res
 */
export const returnData = res => Promise.resolve(res.data)
